<template>
  <div>
    <v-card
      color="#F2F4F4"
      class="mx-auto overflow-hidden rounded-0"
      height="100vh"
    >
      <nav-bar-client />
      <v-subheader> Mis Clientes</v-subheader>
      <scroll-client />
      <new-client />
    </v-card>
  </div>
</template>

<script>
import NewClient from "@/components/home/NewClient.vue";
import NavBarClient from "@/components/home/NavBarClient.vue";
import ScrollClient from "@/components/home/ListClient.vue";

export default {
  components: { NewClient, NavBarClient, ScrollClient },
};
</script>
