import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/home/HomeView.vue';
import Login from '../components/Login.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/login', component: Login },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/detail_client/DetailClientView.vue')
  },
  {
    path: '/section/:id',
    name: 'section',
    component: () => import('../views/section/SectionView.vue')
  },
  {
    path: '/generate-qr',
    name: 'generate-qr',
    component: () => import('../views/GenerateQrView.vue')
  },
];

const router = new VueRouter({
  /* mode: 'history', */ // Esto quita el #
  base: process.env.BASE_URL,
  routes
});

export default router;
