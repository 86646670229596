/* Importar la configuración para la base de datos */
import config from './config_db';

let DB;

export default class DATABASE {
    constructor(nombre, edad) {
        this.nombre = nombre;
        this.edad = edad;
    }
    static async getDb() {
        return new Promise((resolve, reject) => {
            if (DB) { return resolve(DB); }
            console.log('OPENING DB', DB);
            let request = window.indexedDB.open(config.DB_NAME, config.DB_VERSION);
            request.onerror = e => {
                console.log('Error opening db', e);
                reject('Error');
            };
            request.onsuccess = e => {
                DB = e.target.result;
                resolve(DB);
            };
            request.onupgradeneeded = e => {
                console.log('onupgradeneeded');
                let db = e.target.result;
                config.STORES.forEach(e => {
                    db.createObjectStore(e, { keyPath: "id", autoIncrement:true });
                })
            };
        });
    }
    /* Obtener todos los datos de un store en particular */
    static async getAll(storeName) {
        let db = await this.getDb();
        return new Promise(resolve => {
            let trans = db.transaction([storeName], 'readonly');
            trans.oncomplete = () => {
                resolve(items);
            };
            let store = trans.objectStore(storeName);
            let items = [];
            store.openCursor().onsuccess = e => {
                let cursor = e.target.result;
                if (cursor) {
                    items.push(cursor.value)
                    cursor.continue();
                }
            };
        });
    }
    /* Guardar un nuevo registro.. hay que indicarle el nombre del store y pasarle el nuevo registro */
    static async save(storeName, newData) {
        let db = await this.getDb();
        return new Promise(resolve => {
            let trans = db.transaction([storeName], 'readwrite');
            trans.oncomplete = () => {
                resolve();
            };
            let store = trans.objectStore(storeName);
            store.put(newData);

        });
    }
    /* Eliminar un registro.. hay que pasarle el nombre del store y el registro */
    static async delete(storeName, deleteData) {
        let db = await DATABASE.getDb();
        return new Promise(resolve => {
            let trans = db.transaction([storeName], 'readwrite');
            trans.oncomplete = () => {
                resolve();
            };
            let store = trans.objectStore(storeName);
            store.delete(deleteData.id);
        });
    }
    /* Buscar y obtener los datos de un registro.. */
    static async search(storeName, key) {
        let db = await DATABASE.getDb();
        return new Promise(resolve => {
            const trans = db.transaction([storeName]).objectStore(storeName).get(key);
            trans.onsuccess = (event) => {
                var cursor = event.target.result;
                if (cursor) {
                    // entry exists
                    resolve(trans.result);
                } else {
                    // entry doesn't exist
                    resolve('No existe.');
                }
            }
        })
    }
}