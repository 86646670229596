<template>
    <v-dialog v-model="dialogVisible" max-width="400" @click:outside="closeDialog">
      <v-card>
        <v-card-title>
          Actualización Disponible
        </v-card-title>
        <v-card-text>
          Hay una nueva versión de la aplicación disponible. ¿Deseas actualizar?
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="refreshApp">Actualizar</v-btn>
          <v-btn text @click="closeDialog">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { mapActions } from "vuex"; // Si necesitas acciones de Vuex
  
  export default {
    name: "UpdateIndicator",
    data() {
      return {
        dialogVisible: false,
      };
    },
    mounted() {
      this.registerServiceWorker();
    },
    methods: {
      registerServiceWorker() {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register(`${process.env.BASE_URL}service-worker.js`)
            .then(registration => {
              console.log('Service worker registered:', registration);
              this.checkForUpdates(registration);
            })
            .catch(error => {
              console.error('Error during service worker registration:', error);
            });
        }
      },
      checkForUpdates(registration) {
        registration.onupdatefound = () => {
          const newWorker = registration.installing;
          newWorker.onstatechange = () => {
            if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
              // Mostrar el diálogo si hay una nueva versión disponible
              this.dialogVisible = true;
            }
          };
        };
      },
      refreshApp() {
        // Navegar al home antes de recargar
        this.$router.push({ name: 'home' }) // Asegúrate de que 'home' sea el nombre correcto de tu ruta
          .then(() => {
            // Luego recargar la página
            window.location.reload();
          });
      },
      closeDialog() {
        this.dialogVisible = false;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Estilos personalizados (opcional) */
  </style>
  