import db from '@/api/db/class_db';
const store = 'customers';
export default {
    namespaced: true,
    state: {
        customers: [],
        findCustomer: {},
    },
    getters: {
        //Datos transformados
    },
    mutations: {
        //Funciones normales
    },
    actions: {
        //  Operaciones asincrónicas arbitrarias
        async getAll(context) {
            context.state.customers = [];
            let customers = await db.getAll(store);
            customers.forEach(e => {
                context.state.customers.push(e);
            });
        },
        async save(context, newData) {
            await db.save(store, newData);
        },
        async delete(context, deleteData) {
            await db.delete(store, deleteData);
        },
        async search(context, id) {
            context.state.findCustomer = await db.search(store, id);
        }
    },
}