<template>
  <v-app>
    <v-main>
      <div id="app">
        <router-view />
      </div>
      <UpdateIndicator />
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import UpdateIndicator from "@/components/global/UpdateIndicator";
export default {
  name: "App",
  components: { UpdateIndicator },

  data: () => ({
    // Aquí puedes añadir más datos si es necesario
  }),

  computed: {
    ...mapState({
      customers: (state) => state.customers.customers,
    }),
  },
  created() {
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    if (!isAuthenticated && this.$route.path !== '/login') {
      this.$router.push('/login');
    }
  },
  methods: {
    ...mapActions({
      getItems: "customers/getAll",
      saveItem: "customers/save",
      deleteItem: "customers/delete",
    }),

    newData() {
      const dt = {
        id: this.serial(),
        name: "Cesar",
      };
      this.saveItem(dt);
      this.getItems();
    },

    deleteData(item) {
      this.deleteItem(item);
      this.getItems();
    },

    serial() {
      var d = new Date().getTime();
      var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
      return uuid;
    },
  },
  mounted() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        // Cámara disponible; cierra la cámara si no la necesitas inmediatamente
        stream.getTracks().forEach((track) => track.stop());
      })
      .catch((error) => {
        console.error("Permiso de cámara denegado:", error);
      });
  },
};
</script>
