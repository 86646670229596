<template>
    <v-navigation-drawer absolute v-model="drawer" temporary>
        <template v-slot:prepend>
            <v-list-item two-line>
                <v-list-item-avatar color="blue"></v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>{{ user === 0 ? 'Admin' : 'Técnico' }}</v-list-item-title>
                    <!--  <v-list-item-subtitle>
                        Usuario@gmail.com
                    </v-list-item-subtitle> -->
                </v-list-item-content>
            </v-list-item>
        </template>

        <v-divider></v-divider>

        <v-list dense>
            <!-- Compara la ruta actual y aplica una clase activa -->
            <v-list-item v-for="item in items" :key="item.title" link
                :class="{ 'active-route': $route.path === item.route }" @click="navigateTo(item.route)">
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <template v-slot:append>
            <div class="pa-2">
                <v-btn block @click="logout" outlined>
                    Cerrar sesión
                </v-btn>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script>
import config from "@/config.js";
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        user: config.user,
        itemsAdmin: [
            { title: "Dictámenes", icon: "list", route: "/" },
            { title: "Códigos QR", icon: "qr_code", route: "/generate-qr" },
            /*  { title: "Clientes", icon: "group", route: "/generate-qr" }, */
        ],
        itemsTec: [
            { title: "Dictámenes", icon: "list", route: "/" },
        ]
    }),
    computed: {
        drawer: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
        items() {
            return this.user === 0 ? this.itemsAdmin : this.itemsTec;
        }
    },
    methods: {
        navigateTo(route) {
            if (this.$route.path !== route) {
                this.$router.push(route);
            }
            this.drawer = false;
        },
        logout() {
            localStorage.removeItem("isAuthenticated");
            this.$router.push("/login");
        },
    },
};
</script>

<style scoped>
/* Clase activa para la ruta seleccionada */
.active-route {
    background-color: #eaeded;
    color: black !important;
    /* Color distintivo */
    font-weight: bold;
}
</style>