// config.js
export default {
    version: "2.4.0",
    user: 0,
    session_user: "Admin",
    session_password: "2025UVR018@"
};

/*  


*/

/* 
tecnico = 1 
user: 1,
session_user: "UVR-tec1@uviereport.com",
session_password: "UVR2025"

admin = 0
user: 0,
session_user: "Admin",
session_password: "2025UVR018@"
 */