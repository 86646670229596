export default {
    DB_NAME: 'uvrdb',//Nombre de la base de datos
    DB_VERSION: 1,//Versión de la base de datos..
    STORES://Todas los stores que contendrá la base de datos..
        [
            'customers',
            /*    'rush',
               'substations',
               'transformers',
               'boards',
               'contacts',
               'areas',
               'lands', */
        ]
}
