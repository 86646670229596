<template>
  <v-container class="primary d-flex align-center justify-center fill-height">
    <v-card class="pa-4" max-width="360">
      <v-card-title class="text-center justify-center">
        <span class="display-1 font-weight-bold primary--text">UVIEREPORT</span>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            label="Usuario"
            v-model="username"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            label="Contraseña"
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            outlined
            dense
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            @click:append="togglePasswordVisibility"
          ></v-text-field>
          <v-btn color="primary" block @click="login" class="mt-4"
            >Ingresar</v-btn
          >
          <v-alert v-if="error" type="error" dense class="mt-2">
            {{ error }}
          </v-alert>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import config from "@/config.js";
export default {
  data() {
    return {
      username: "",
      password: "",
      error: "",
      showPassword: false,
      staticUser: config.session_user,
      staticPassword: config.session_password,
    };
  },
  methods: {
    login() {
      const staticUser = this.staticUser;
      const staticPassword = this.staticPassword;

      if (this.username === staticUser && this.password === staticPassword) {
        localStorage.setItem("isAuthenticated", true);
        this.$router.push("/");
      } else {
        this.error = "Usuario o contraseña incorrectos";
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped>
.fill-height {
  height: 100vh;
}
</style>
