<template>
  <div>
    <div v-if="items.length === 0">
      <p class="text-center">..Sin Clientes...</p>
    </div>
    <div v-else>
      <v-virtual-scroll :items="items" :item-height="80" style="height: 80vh">
        <template v-slot:default="{ item }">
          <v-card elevation="0" outlined>
            <div>
              <v-list-item @click="open(item)">
                <v-list-item-avatar>
                  <v-avatar tile color="#273746" class="white--text">
                    {{ item.initials }}</v-avatar
                  >
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title> {{ item.tradename }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    item.municipality
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action style="font-style: italic; font-size: 13px">
                  {{ item.date }}

                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon color="#273746">fact_check </v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click="openDeleteModal(item)">
                        <v-list-item> Borrar </v-list-item>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-card>
        </template>
      </v-virtual-scroll>
        <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Borrar </v-card-title>
        <v-card-text> ¿Seguro que desea borrar {{name}}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#273746" text @click="dialog = false"> Cancelar </v-btn>
          <v-btn outlined color="#273746" @click="remove(idItemDelete)">
            Si, Borrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
  
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "ScrollClient",
  data() {
    return {
      idItemDelete: null,
      dialog: false,
      name:'',
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.customers.customers,
    }),
  },
  created() {
    this.getItems();
  },
  methods: {
    ...mapActions({
      getItems: "customers/getAll",
      deleteItem: "customers/delete",
    }),
    open(item) {
      console.log(item.id);
      this.$router.push(`section/${item.id}`);
    },
    openDeleteModal(item) {
      this.idItemDelete = item;
      this.dialog = true;
      this.name = item.tradename
    },
    remove(item) {
      this.deleteItem(item);
      this.getItems();
      this.dialog = false;
    },
  },
};
</script>