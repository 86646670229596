<template>
  <div>
    <v-app-bar elevation="0" :color="user === 0 ? 'primary' : '#000000'" style="color: #d5dbdb" dense>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="white"></v-app-bar-nav-icon>
      <!--  <v-btn icon>
      <v-icon size="30" color="#D5DBDB" @click="logout">logout</v-icon>
    </v-btn> -->
      <v-toolbar-title>{{ user === 0 ? "UVIEREPORT" : "UVR(Técnico)" }} v.{{
        version
        }}</v-toolbar-title>
      <!-- <v-toolbar-title>UVR(Técnico) v.2.2.13</v-toolbar-title> -->
      <v-spacer></v-spacer>
      <v-dialog v-model="dialog" persistent>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon size="30" color="#D5DBDB">folder_open</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-container>
            <v-card-title class="text-h5" style="color: #273746">
              Importar Dictamen
            </v-card-title>
          </v-container>

          <v-card-text>
            <v-row align="center" justify="space-around">
              <v-container>
                <v-file-input v-model="file" color="primary" counter label="Archivo .uvr"
                  placeholder="Select your files" prepend-icon="mdi-paperclip" outlined :show-size="1000">
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" color="primary" dark label small>
                      {{ text }}
                    </v-chip>

                    <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 mx-2">
                      +{{ files.length - 2 }} File(s)
                    </span>
                  </template>
                </v-file-input>
              </v-container>
              <v-btn depressed color="primary" :disabled="!fileValidation" @click="saveImport()">
                Importar
              </v-btn>
            </v-row>

            <!--    <v-btn outlined> DICTAMEN </v-btn> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn outlined color="#273746" @click="(dialog = false), (file = null)">
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>
    <NavigationDrawer v-model="drawer"></NavigationDrawer>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import config from "@/config.js";
import NavigationDrawer from "../global/NavigationDrawer.vue";
export default {
  name: "NavBarClient",
  components: { NavigationDrawer },
  data: () => ({
    file: null,
    dialog: false,
    version: config.version,
    user: config.user,
    drawer: false,
  }),
  computed: {
    fileValidation() {
      if (!this.file || !this.file.name) return false;
      var allowedExtensions = /(\.uvr)$/i;
      if (!allowedExtensions.exec(this.file.name)) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      getItems: "customers/getAll",
      saveItem: "customers/save",
      deleteItem: "customers/delete",
    }),
    //Trabajando en esta funcion a fondo..
    deleteAll() {
      window.indexedDB.webkitGetDatabaseNames().onsuccess = function (sender) {
        var r = sender.target.result;
        for (var i in r) indexedDB.deleteDatabase(r[i]);
      };
    },
    saveImport() {
      //console.log(this.file);
      //if (!this.files) {this.data = "No File Chosen"}
      var reader = new FileReader();
      reader.readAsText(this.file);
      reader.onload = () => {
        let obj = JSON.parse(reader.result);
        //Crear o sobrescribir..
        delete obj.id;
        this.createClient(obj);
        this.file = null;
        this.dialog = false;
      };
    },
    createClient(data) {
      this.saveItem(data);
      this.getItems();
    },
    logout() {
      localStorage.removeItem('isAuthenticated');
      this.$router.push('/login');
    }
  },
};
</script>
